import { Link } from "react-router-dom";
import SamplesPlayer from "../../Components/SamplesPlayer";

const Samples = () => {
  return (
    <div>
      <div className="samplesHeader">
        <div className="goBackButtonContainer">
          <Link className="goBackButton" to="/">
            <span>🏃&zwj;♂️</span>Volver
          </Link>
        </div>
      </div>
      <div className="tabContent">
        <SamplesPlayer />
      </div>
    </div>
  );
};

export default Samples;
