import { useState } from "react";

const SamplesPlayer = () => {
  const [streamIsActive, setStreamIsActive] = useState(false);
  const [errorInSearch, setErrorInSearch] = useState(false);
  const [iframeInputValue, setIframeInputValue] = useState();
  const [chatInputValue, setChatInputValue] = useState({
    visible: false,
    src: "",
  });
  const [loadedValues, setLoadedValues] = useState({
    iframeSrc: "",
    chatIsVisible: false,
    chatSrc: "",
  });

  const loadPlayer = (e) => {
    e.preventDefault();
    if (iframeInputValue) {
      setStreamIsActive(true);
      setLoadedValues({
        iframeSrc: iframeInputValue,
        chatIsVisible: chatInputValue.src && chatInputValue.visible,
        chatSrc: chatInputValue.src,
      });
    } else {
      setErrorInSearch(true);
      setChatInputValue({
        visible: false,
        src: "",
      });
    }
    document.getElementById("playerForm").reset();
  };

  return (
    <>
      <form className="form" onSubmit={loadPlayer} id="playerForm">
        <input
          className="input"
          placeholder="Ingresá la src del iFrame"
          id="playerInput"
          name="playerInput"
          type="text"
          onChange={(e) => setIframeInputValue(e.target.value)}
        />
        <input
          className="input"
          placeholder="Ingresá la src del chat"
          id="chatInput"
          name="chatInput"
          type="text"
          onChange={(e) =>
            setChatInputValue({ visible: true, src: e.target.value })
          }
        />
        <button className="button" type="submit">
          Buscar
        </button>
        {errorInSearch && (
          <div className="errorMsg">Necesitás ingresar una SRC de video!!</div>
        )}
      </form>
      {streamIsActive ? (
        <div className="player-container">
          <div className="player-chat">
            <div className="player-wrapper res-16by9">
              <iframe
                src={loadedValues.iframeSrc}
                frameborder="0"
                allow="autoplay; fullscreen"
                title="videoPlayer"
              ></iframe>
            </div>
            {loadedValues.chatIsVisible && (
              <div className="chat-wrapper">
                <iframe
                  src={loadedValues.chatSrc}
                  width="100%"
                  height="100%"
                  frameborder="0"
                  title="playerChat"
                ></iframe>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="noStreamContainer">
          <h1 className="logo glitch has-text-centered small">nzt</h1>
          <div className="has-text-centered">
            <p className="has-text-white is-size-4">
              Para testear un stream en proceso, insertá la SRC del video y del
              chat respectivamente, y presioná el botón para buscar.
            </p>
          </div>
        </div>
      )}
    </>
  );
};

export default SamplesPlayer;
