import logoVimeo from "../Images/vimeo.png";
import logoWowza from "../Images/wowza.png";
import logoMasvivo from "../Images/masvivo.png";
import logoWirecast from "../Images/wirecast.png";
import esteban from "../Images/esteban.png";

const AboutUs = () => {
  return (
    <div className="has-background-black">
      <div className="section about">
        <div className="esteban-data">
          <h2>
            Nosotros<span>.</span>
          </h2>
          <p>
            Somos un equipo ágil y preparado para resolver cualquier situación,
            creamos, proponemos, y hacemos lo necesario para que cada proyecto
            sea la mejor versión de sí mismo.
          </p>
          <p>
            Contamos con más de 20 años de experiencia en la producción técnica
            de eventos en vivo y grabaciones. <span>Esteban Varela</span>,
            founder de NZTStudios, lidera personalmente cada proyecto,
            garantizando así, el resultado.
          </p>
          <p>
            Tenemos el mejor equipamiento disponible, pero somos versátiles para
            adaptarnos a la medida de cada proyecto.
          </p>
          <div className="partners">
            <div className="partner-wrapper">
              <a target="_blank" href="https://vimeo.com/es/" rel="noreferrer">
                <img src={logoVimeo} alt="vimeo" />
              </a>
            </div>
            <div className="partner-wrapper">
              <a target="_blank" href="https://www.wowza.com/" rel="noreferrer">
                <img src={logoWowza} alt="wowza" />
              </a>
            </div>
            <div className="partner-wrapper">
              <a target="_blank" href="https://masvivo.tv/" rel="noreferrer">
                <img src={logoMasvivo} alt="masvivo" />
              </a>
            </div>
            <div className="partner-wrapper">
              <a
                target="_blank"
                href="https://www.telestream.net/wirecast/"
                rel="noreferrer"
              >
                <img src={logoWirecast} alt="wirecast" />
              </a>
            </div>
          </div>
        </div>
        <div className="esteban-img">
          <img src={esteban} alt="estebanVarela" />
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
