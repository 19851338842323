import { Link } from "react-router-dom";

const Header = () => {
  return (
    <div className="hero">
      <Link to={"/samples"}>
        <h1 className="logo glitch has-text-centered">nzt</h1>
      </Link>
    </div>
  );
};

export default Header;
