import streamImg from "../Images/stream.jpg";
import recordingImg from "../Images/cam.jpg";
import prodImg from "../Images/postpo.jpg";

const Services = () => {
  return (
    <div className="container">
      <div className="services-wrapper">
        <div className="img-service flecha right img-streaming">
          <div className="flecha-inner right">
            <img alt="servicios" src={streamImg} />
          </div>
        </div>
        <div className="data-service streaming">
          <h2>Streaming</h2>
          <p>
            Producción técnica para transmisión de eventos en vivo y grabados.
          </p>
        </div>
        <div className="data-service recording">
          <h2>Recording</h2>
          <p>Montaje para grabación de eventos, o transmisión en vivo.</p>
        </div>
        <div className="img-service flecha left img-recording">
          <div className="flecha-inner left">
            <img alt="servicios" src={recordingImg} />
          </div>
        </div>
        <div className="img-service flecha right img-postpo">
          <div className="flecha-inner right">
            <img alt="servicios" src={prodImg} />
          </div>
        </div>
        <div className="data-service postpo">
          <h2>Post-production</h2>
          <p>Edición de audio y video para eventos en vivo o grabados.</p>
        </div>
      </div>
    </div>
  );
};

export default Services;
