import Header from "../../Components/Header";
import Services from "../../Components/Services";
import AboutUs from "../../Components/AboutUs";
import Footer from "../../Components/Footer";

const Home = () => {
  return (
    <div>
      <Header />
      <section className="section">
        <Services />
      </section>
      <AboutUs />
      <Footer />
    </div>
  );
};

export default Home;
