import map from "../Images/mapa.JPG";

const Footer = () => {
  return (
    <footer>
      <div className="footer-wrapper">
        <div className="mapa">
          <a
            target="blank"
            href="https://www.google.com.ar/maps/place/NZT+Studios/@-34.5636043,-58.4826232,17z/data=!4m5!3m4!1s0x95bcb68a2b42967b:0x95fe298d5cab3b9d!8m2!3d-34.5636043!4d-58.4804345"
          >
            <img src={map} alt="mapa" />
          </a>
        </div>
        <div className="footer-data">
          <a target="blank" href="mailto:esteban@nztstudios.com">
            <h3>
              <span>📧</span>esteban@nztstudios.com
            </h3>
          </a>
          <a
            target="blank"
            href="https://api.whatsapp.com/send?phone=541168207302"
          >
            <h3>
              <span>📱</span>+54 911 6820-7302
            </h3>
          </a>
        </div>
      </div>
      <div className="sign">
        <a target="blanck" href="https://www.linkedin.com/in/facuvarela/">
          Made with <span>💚</span> by <b>Cufa!</b>
        </a>
      </div>
    </footer>
  );
};

export default Footer;
